import { Injectable } from '@angular/core';
import mappingDataCA from '@config/form-config.json';
import mappingDataHub from '@config/form-configHub.json';
import menuDataCA from '@config/menu-config.json';
import menuDataHub from '@config/menu-configHub.json';
import { ContextEnum } from '@enums';
import { ReplaySubject } from 'rxjs';
import { informationContentResource } from './information-config-resource';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _currentContext: ContextEnum;
  private _initialTopic: string;

  private _appOpenApiConfig: any = null;
  private _hubOpenApiConfig: any = null;
  private _openApiArray = [null, null];

  public context = new ReplaySubject<ContextEnum>();

  public appPath: string;

  private mappingData = [mappingDataCA, mappingDataHub];
  private menuConfigData = [menuDataCA, menuDataHub];

  constructor() {
    this.currentContext = this.menuConfigData[0].flatMap(x => x.subtopics).find(y => y['appDefault'])
      ? ContextEnum.configApp
      : ContextEnum.bankHub;
    this._initialTopic = this.menuConfigData[this.hubIndex].flatMap(x => x.subtopics).find(y => y['appDefault'])?.name;
  }

  public isHubApi() {
    return this._currentContext === 'hub' ? true : false;
  }

  get currentContext(): ContextEnum {
    return this._currentContext;
  }
  set currentContext(value: ContextEnum) {
    this._currentContext = value;
    this.context.next(this._currentContext);
  }

  public get appOpenApiConfig(): any {
    return this._appOpenApiConfig;
  }
  public set appOpenApiConfig(value: any) {
    this._appOpenApiConfig = value;
    this._appOpenApiConfig.components.schemas.InformationContentResource = informationContentResource;
    this.openApiArray = [this.appOpenApiConfig, this.hubOpenApiConfig];
  }

  public get hubOpenApiConfig(): any {
    return this._hubOpenApiConfig;
  }
  public set hubOpenApiConfig(value: any) {
    this._hubOpenApiConfig = value;
    this._hubOpenApiConfig.components.schemas.InformationConfigResource = informationContentResource;
    this.openApiArray = [this.appOpenApiConfig, this.hubOpenApiConfig];
  }

  public get openApiArray() {
    return this._openApiArray;
  }
  public set openApiArray(value) {
    this._openApiArray = value;
  }

  get hubIndex() {
    return this.currentContext === 'hub' ? 1 : 0;
  }

  get currentOpenApiConfig() {
    return this.openApiArray[this.hubIndex];
  }

  get menuConfig() {
    return this.menuConfigData[this.hubIndex];
  }

  get initialMenuConfig() {
    return this._initialTopic;
  }

  getNumberOfDisplayedFields(entity): number {
    const config = this.mappingData[this.hubIndex].entities[entity.toLowerCase()];
    if (config?.displayedColumns) {
      return config.displayedColumns.length;
    } else {
      return 0;
    }
  }

  /**
   * @returns the description of the given entity
   * @param entity entity to get the description from
   */
  getEntityDescription(entity: string): string {
    return this.openApiArray[this.hubIndex]['components']['schemas'][entity]?.description || '';
  }

  /**
   * @return the formTitle of the given entity
   * @param entity entity to get the formTitle from
   */
  getEntityFormTitle(entity): string {
    const currentEntity = entity.toLowerCase();
    const config = this.mappingData[this.hubIndex].entities[currentEntity];
    if (config?.formTitle) {
      return config.formTitle;
    } else {
      return '';
    }
  }

  /**
   * @return the displayed columns as array of strings of the given entity
   * @param entity entity to get the displayedColumns from
   */
  getDisplayedColumns(entity): string[] {
    for (const en in this.mappingData[this.hubIndex].entities) {
      if (entity.toLowerCase() === en) {
        return this.mappingData[this.hubIndex].entities[en].displayedColumns || [];
      }
    }
    return [];
  }

  getDisplayedColumnsVP(entity): string[] {
    for (const en in this.mappingData[this.hubIndex].entities) {
      if (entity.toLowerCase() === en) {
        return this.mappingData[this.hubIndex].entities[en].displayedColumnsVP || [];
      }
    }
    return [];
  }
  /**
   * @returns a label for the given entity
   * @param entity entity to get the displayLabel from
   */
  getDisplayLabel(entity): string {
    const config = this.mappingData[this.hubIndex].entities[entity.toLowerCase()];
    if (config.displayedColumns) {
      return config.displayedColumns[0];
    } else {
      return null;
    }
  }

  /**
   * @returns if the fields of the given entity should be dragable
   * @param entity entity to get information from
   */
  areFieldsDragable(entity): boolean {
    return this.mappingData[this.hubIndex].entities[entity.toLowerCase()].fieldsDragable;
  }

  /**
   * @returns a plural title for the given entity
   * @param entity entity to get the plural title from
   */
  getPluralTitle(entity): string {
    return this.mappingData[this.hubIndex].entities[entity.toLowerCase()].pluralTitle;
  }

  /**
   * @returns the endpoint for the given entity
   * @param entity entity to get the endpoint from
   */
  getEndpoint(entity): string {
    return this.mappingData[this.hubIndex].entities[entity.toLowerCase()]?.endpoint || null;
  }

  getHubEndpoint(entity): string {
    return this.mappingData[1].entities[entity.toLocaleLowerCase()]?.endpoint || null;
  }

  /**
   * @returns the field that should get displayed for the given entity in dropdown inputs
   * @param entity entity to get the reference field from
   */
  getRefDisplay(entity): string {
    return this.mappingData[this.hubIndex].entities[entity.toLowerCase()].refDisplay;
  }

  getEnumLabel(entity: string, enumString: string): string {
    const enums = this.mappingData[this.hubIndex].entities[entity.toLowerCase()]?.enumLabels;
    return enums && enums[enumString] ? enums[enumString] : enumString;
  }

  hasVpColumns(entity: string): boolean {
    return this.mappingData[this.hubIndex].hasVpColumns.includes(entity);
  }

  getNoRefEntities(entity: string): boolean {
    return this.mappingData[this.hubIndex].noRefs.includes(entity);
  }

  get m2mFields(): string[] {
    return this.mappingData[this.hubIndex].m2mfields;
  }

  get innerFormFields(): string[] {
    return this.mappingData[this.hubIndex].innerFormFields;
  }

  get regularDescriptions(): string[] {
    return this.mappingData[this.hubIndex].regularDescription;
  }

  get noLocks(): string[] {
    return this.mappingData[this.hubIndex].noLocks;
  }
}
