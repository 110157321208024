import { Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ENVIRONMENT_DEFAULTS } from './defaults';

export const environment = {
  ...ENVIRONMENT_DEFAULTS,
  name: 'qa',
  production: true,
  enableOnlinePing: true,
  apiConsultationAppUrl: 'http://ec2-52-57-6-109.eu-central-1.compute.amazonaws.com:8002/',
  apiBankHubUrl: 'http://ec2-52-57-6-109.eu-central-1.compute.amazonaws.com:9002/',

  // bgzv_test
  cognitoUserPoolId: 'eu-central-1_Nkz9IRfJN',
  cognitoClientId: '2fhq6cu5tjbu3moh5me4tvn5kk',
};

export const ENVIRONMENT_SPECIFIC_IMPORTS = [];

export const ENVIRONMENT_SPECIFIC_PROVIDERS = [];

export function ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT(component: any, injector: Injector = null) {
  return createCustomElement(component, { injector: injector });
}
